import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "../components/Theater/TheaterVideo"
import { Link } from "gatsby"

var wisdomTeethData = require("../_archives/procedures/wisdom-teeth-removal.json")

const WisdomStepOnePage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          title="Watch the Wisdom For Wisdom Video"
          description="Follow the Wisdom For Wisdom steps to apply"
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content program-page-wisdom">
          <div className="columns video-page top-section white-back has-text-centered">
          <div className="column">
              <h1>Step 1: Watch the Video</h1>
            </div>
          </div>

          <section className="body-section" style={{marginTop: 0}}>
            <div className="columns">
            <div className="column is-2"></div>
            <div className="column">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${wisdomTeethData.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              hasWatchVideo={true}
            >
              <ImageMeta
                cloudName="nuvolum"
                publicId="AOMS/DEV/procedure-hero-desktop-wisdom-teeth"
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
              />
            </TheaterVideo>

            </div>
            <div className="column is-2"></div>
            </div>

            
          </section>

          <section className="body-section" style={{paddingTop: 0}}>
            <div className="columns">
            <div className="column is-6"></div>
            <div className="column">
            
<h3>Watch this video about wisdom teeth carefully. After you watch it, we will quiz you on what you learned in step 2, so pay attention!</h3>
<div style={{textAlign: "center"}}><Button className="wisdom-button" buttonText="Step 2: Take the Quiz" href="/wisdom-step-2" /></div>

            </div>
            <div className="column is-6"></div>
            </div>

            
          </section>

        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default WisdomStepOnePage
